// Importa el menú de hamburguesa de inmediato
import './plugins/menus/hamburger';

document.addEventListener('DOMContentLoaded', function () {
    // Verifica la existencia de elementos antes de cargar módulos adicionales
    // if (document.querySelector('.swiper')) {
    //     import('./vendors/swiper');
    // }

    if (document.querySelector('.js-mouseanimate-trigger')) {
        import('./plugins/mouseanimate');
    }
});
